const sessionRepository = (axios) => async (data) => {
  try {
    const response = await axios.post('/sessao', data);
    const jwtAuthToken = response.data;
    return jwtAuthToken;

    // const response = await axios.put('app/app', data);

    // return response.data.retorno[0].retorno
  } catch (error) {
    throw error;
  }
};

export default sessionRepository;
