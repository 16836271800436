const buscaDuplicataUseCase = (repository) => async (duplicata) => {
  try {
    // const payload = constructPayload('leDuplicata', duplicata)

    return await repository(duplicata);
  } catch (error) {
    throw error;
  }
};

export default buscaDuplicataUseCase;
