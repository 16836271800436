import { getUser } from "@/core/data/domain/jwtAuth"
// import { constructPayload } from "@/core/data/domain/model/payloadStruct"

const validarDuplicataUseCase = (repository) => async (codigoDuplicata) => {
  try {
    const data = {
      codigo: codigoDuplicata,
      usuario: getUser().usuario
    }
    // const payload = constructPayload('baixaDuplicata', data)

    return await repository(data)
  } catch (error) {
    throw error;
  }
};

export default validarDuplicataUseCase;
