import { axiosInstance } from '@/plugins/axios';
import DefaultController from '@/module/default/controller/defaultController';
import LoginController from '@/module/login/controller/loginController';
import HomeController from '@/module/home/controller/homeController';
import DashboardController from '@/module/dashboard/controller/dashboardController';

import sessionUseCase from '@/module/login/domain/usecase/sessionUseCase';
import sessionRepository from '@/module/login/data/repository/sessionRepository';
import buscaDuplicataUseCase from '@/module/dashboard/domain/usecase/buscaDuplicataUseCase';
import buscaDuplicataRepository from '@/module/dashboard/data/repository/buscaDuplicataRepository';
import validarDuplicataRepository from '@/module/dashboard/data/repository/validarDuplicataRepository';
import validarDuplicataUseCase from '@/module/dashboard/domain/usecase/validarDuplicataUseCase';
import verificaUsuarioRepository from '@/module/login/data/repository/verificaUsuarioRepository';
import refreshUseCase from '@/module/login/domain/usecase/refreshUseCase';
import alterarSenhaUsecase from '@/module/login/domain/usecase/alterarSenha';
import alterarSenhaRepository from '@/module/login/data/repository/alterarSenha';
import primeiroAcessoUsecase from '@/module/login/domain/usecase/primeiroAcesso';
import primeiroAcessoRepository from '@/module/login/data/repository/primeiroAcesso';
import ePrimeiroAcessoUsecase from '@/module/login/domain/usecase/ePrimeiroAcesso';
import ePrimeiroAcessoRepository from '@/module/login/data/repository/ePrimeiroAcesso';
import validaUsuarioUseCase from '@/module/login/domain/usecase/validaUsuarioUseCase';

const sessionRepositoryImpl = sessionRepository(axiosInstance);
const verificaUsuarioRepositoryImpl = verificaUsuarioRepository(axiosInstance);
const validaUsuarioUseCaseImpl = validaUsuarioUseCase(
  verificaUsuarioRepositoryImpl
);
const sessionUseCaseImpl = sessionUseCase(sessionRepositoryImpl);

const buscaDuplicataRepositoryImpl = buscaDuplicataRepository(axiosInstance);
const buscaDuplicataUseCaseImpl = buscaDuplicataUseCase(
  buscaDuplicataRepositoryImpl
);

const ePrimeiroAcessoRepositoryImpl = ePrimeiroAcessoRepository(axiosInstance);
const ePrimeiroAcessoUsecaseImpl = ePrimeiroAcessoUsecase(
  ePrimeiroAcessoRepositoryImpl
);
const primeiroAcessoRepositoryImpl = primeiroAcessoRepository(axiosInstance);
const primeiroAcessoUsecaseImpl = primeiroAcessoUsecase(
  primeiroAcessoRepositoryImpl
);
const alterarSenhaRepositoryImpl = alterarSenhaRepository(axiosInstance);
const alterarSenhaUsecaseImpl = alterarSenhaUsecase(alterarSenhaRepositoryImpl);

const refreshUseCaseImpl = refreshUseCase(sessionRepositoryImpl);

const validarDuplicataRepositoryImpl =
  validarDuplicataRepository(axiosInstance);
const validarDuplicataUseCaseImpl = validarDuplicataUseCase(
  validarDuplicataRepositoryImpl
);

const defaultController = (context) => new DefaultController(context);
const homeController = (context) => new HomeController(context);
const loginController = (context) =>
  new LoginController(
    context,
    sessionUseCaseImpl,
    ePrimeiroAcessoUsecaseImpl,
    primeiroAcessoUsecaseImpl,
    alterarSenhaUsecaseImpl,
    validaUsuarioUseCaseImpl
  );
const dashboardController = (context) =>
  new DashboardController(
    context,
    buscaDuplicataUseCaseImpl,
    validarDuplicataUseCaseImpl,
    refreshUseCaseImpl
  );

export {
  defaultController,
  loginController,
  homeController,
  dashboardController
};
