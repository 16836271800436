import { URL } from "@/constants"
import sessionRepository from "@/module/login/data/repository/sessionRepository";
import refreshUseCase from "@/module/login/domain/usecase/refreshUseCase";
import axios from "axios"

const axiosInstance = axios.create({
    baseURL: URL
})

axiosInstance.interceptors.response.use((response) => {
    return response;
}, async (err) => {
    const originalRequest = err.config;
    const status = err.response ? err.response.status : null

    if (status === 401) {
        const refreshRepositoryImpl = sessionRepository(axiosInstance);
        const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);
        try {
            const dados = JSON.parse(localStorage.getItem('auth'))
            await refreshUseCaseImpl();
            originalRequest.headers['Authorization'] = 'Bearer ' + dados.token;
            return axiosInstance(originalRequest);
        } catch (error) {
            return Promise.reject();
        }
    }
    return Promise.reject(err);
})

export { axiosInstance };
