<template>
  <div>
    <v-row justify="center" align="center" style="height: 80vh">
      <v-col cols="12">
        <v-card flat class="mx-auto" elevation="0" max-width="800px">
          <v-card-text class="mx-auto">
            <v-row align="center" justify="center">
              <v-col
                cols="12"
                md="8"
                sm="8"
                lg="6"
                v-if="controller.step !== 2"
              >
                <p class="text-center text-h5">
                  Informe o código do QRcode <v-icon>mdi-qrcode</v-icon>
                </p>
                <v-text-field
                  class="centered-input"
                  :loading="controller.loading"
                  :disabled="controller.loading"
                  :rules="[rules.required]"
                  v-model="controller.duplicataForm.codigo"
                  @keyup.enter="controller.buscarDuplicata()"
                >
                  <template v-slot:append-outer>
                    <v-icon
                      large
                      color="primary"
                      @click="controller.buscarDuplicata()"
                      :disabled="
                        controller.loading || !controller.duplicataForm.codigo
                      "
                    >
                      mdi-magnify
                    </v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="8"
                sm="8"
                lg="10"
                v-if="controller.step !== null"
              >
                <duplicata :controller="controller" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { dashboardController } from '@/di/di';
import duplicata from '../components/duplicata.vue';

export default {
  data: (context) => ({
    controller: dashboardController(context),
    rules: {
      required: (value) => {
        return !!value || 'Campo obrigatório';
      }
    }
  }),
  components: {
    duplicata
  }
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
