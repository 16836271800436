import { setUser } from "@/core/data/domain/jwtAuth"
import Handler from "@/core/data/domain/usecase/handler"

const validaUsuarioUseCase = (repository) => async (form) => {
  try {
    const response = await repository(form)

    if (response !== 'SIM') {
      throw 'Usuário ou senha inválidos'
    }

    setUser(form)
  } catch (error) {
    throw error
  }
}

export default validaUsuarioUseCase