<template>
  <v-app>
    <div>
      <v-app-bar
        app
        color="primary"
        class="rounded-xl rounded-tl-0 rounded-tr-0 no-print"
      >
        <v-img src="consultors.png" max-width="45" max-height="45" contain />
        <v-spacer></v-spacer>
        <v-toolbar-title class="white--text"
          >CGI Software - Carta Frete</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn outlined color="white" @click="controller.logout()">
          <v-icon dense> mdi-logout </v-icon>
          Sair
        </v-btn>
      </v-app-bar>
      <v-main>
        <div class="ma-5 mt-6">
          <component :is="controller.component" />
        </div>
      </v-main>
      <v-footer color="primary lighten-1" padless fixed>
        <v-row justify="center">
          <v-col cols="12" class="primary white--text text-center">
            <v-icon
              v-for="social in controller.redesSociais"
              :key="social.nome"
              @click="controller.openWindow(social.link)"
              color="black"
              class="mx-4 white--text"
              size="24px"
              >{{ social.icon }}</v-icon
            >
          </v-col>
          <v-col
            class="primary lighten-1 py-4 text-center white--text"
            cols="12"
          >
            {{ new Date().getFullYear() }} — <strong>CGI Software</strong>
          </v-col>
        </v-row>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
import { homeController } from '@/di/di';

export default {
  data: (context) => ({
    controller: homeController(context)
  }),
  mounted() {
    this.controller.mounted();
  }
};
</script>

<style></style>
