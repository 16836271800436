import Handler from "@/core/data/domain/usecase/handler";
import { URL } from "../../../../constants"


const alterarSenhaRepository = (axios) => async (data, form) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.put(`${URL}/cfe/altera-senha`, form, {
      headers: {
        authorization: `Bearer ${dados.token}`
    }
    });
    return response.data == 'SIM';
  } catch (error) {
    throw await await new Handler().trataErro(error);
  }
};

export default alterarSenhaRepository;
