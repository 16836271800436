import Vue from 'vue';
import App from './App.vue';

import VueTheMask from 'vue-the-mask';
import dayjs from 'dayjs';
import cids from 'cids-cgi/lib';

import router from './router';
import vuetify from './plugins/vuetify';

import './registerServiceWorker';

Vue.config.productionTip = false;
Vue.prototype.defaultNavigator = null;
Vue.prototype.dayjs = dayjs;
Vue.use(VueTheMask);
Vue.use(cids);

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
