<template>
  <div>
    <v-app style="background-color: #f4f5fa">
      <component :is="controller.component" />
    </v-app>
  </div>
</template>

<script>
import { defaultController } from '@/di/di';

export default {
  data: (content) => ({
    controller: defaultController(content)
  }),
  mounted() {
    this.controller.mounted();
  }
};
</script>
