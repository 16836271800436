import Vue from 'vue';
import Dashboard from '@/module/dashboard/view/Dashboard';

import { clearUser } from '@/core/data/domain/jwtAuth';
import { defaultNavigator } from '@/core/data/domain/navigate';

export default class HomeController {
  component = Dashboard;
  redesSociais = [
    {
      nome: 'Facebook',
      icon: 'mdi-facebook',
      link: 'https://www.facebook.com/CGISoftware'
    },
    {
      nome: 'Instagram',
      icon: 'mdi-instagram',
      link: 'https://www.instagram.com/cgisoftware/'
    },
    {
      nome: 'Linkedin',
      icon: 'mdi-linkedin',
      link: 'https://www.linkedin.com/company/cgisoftware/about/'
    },
    {
      nome: 'Twitter',
      icon: 'mdi-twitter',
      link: 'https://twitter.com/CgiSoftware'
    }
  ];

  constructor(context) {
    this.context = context;
  }

  mounted() {
    Vue.prototype.navigator = this;
  }

  navigate(screen) {
    if (!screen) {
      this.component = Dashboard;
      return;
    }

    this.component = screen;
  }

  openWindow(socialLink) {
    window.open(socialLink, '_blank');
  }

  logout() {
    clearUser();
    defaultNavigator('login');
  }
}
