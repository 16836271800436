import sessionRepository from "../../data/repository/sessionRepository";
import refreshUseCase from "./refreshUseCase";
import { axiosInstance } from "@/plugins/axios";

const ePrimeiroAcessoUsecase = (repository) => async () => {
  try {
    const refreshRepositoryImpl = sessionRepository(axiosInstance);
    const refreshUseCaseImpl = refreshUseCase(refreshRepositoryImpl);
    await refreshUseCaseImpl();

    const token = localStorage.getItem("auth");
    return await repository(token);
  } catch (error) {
    throw error;
  }
};

export default ePrimeiroAcessoUsecase;
