import jwt_decode from "jwt-decode";

const refreshUseCase = (repository) => async () => {
    try {
        const token = localStorage.getItem("auth")
        var decoded = jwt_decode(token);
        const newJwtAuthToken = await repository(decoded.access)
        localStorage.setItem('auth', JSON.stringify(newJwtAuthToken))
    } catch (error) {
        throw error;
    }
};


export default refreshUseCase