const sessionUseCase = (repository) => async (user) => {
  try {
    const jwtAuthToken = await repository(user)
    localStorage.setItem('auth', JSON.stringify(jwtAuthToken))
  } catch (error) {
    throw error;
  }
};

export default sessionUseCase;
