import Handler from '@/core/data/domain/usecase/handler'

const verificaUsuarioRepository = (axios) => async (data) => {
  try {
    const token = JSON.parse(localStorage.getItem("auth"), "{}")

    const response = await axios.post('/cfe/verifica-usuario', data, {
      headers: {
        "Authorization": `Bearer ${token.token}`
      }
    });

    return response.data.retorno[0].retorno
  } catch (error) {
    throw await new Handler().trataErro(error)
  }
};

export default verificaUsuarioRepository;
