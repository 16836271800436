'use strict';

const SESSION_TOKEN_KEY = 'auth-cfe';
const SESSION_USER_KEY = 'user-cfe';

export function setUser(user) {
  sessionStorage.setItem(SESSION_USER_KEY, JSON.stringify(user));
}

export function setJwtAuthToken(token) {
  sessionStorage.setItem(SESSION_USER_KEY, JSON.stringify(token));
}

export function isUserLogged() {
  return sessionStorage.getItem(SESSION_USER_KEY) !== null;
}

export function getUser() {
  return JSON.parse(sessionStorage.getItem(SESSION_USER_KEY));
}

export function clearUser() {
  sessionStorage.removeItem(SESSION_USER_KEY);
  sessionStorage.removeItem(SESSION_TOKEN_KEY);

  return sessionStorage.getItem(SESSION_USER_KEY) === null;
}
