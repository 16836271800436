import Duplicata from '../domain/model/duplicata';
import { snackbar, alert } from 'cids-cgi/lib/util';

class DashboardController {
  duplicataForm = new Duplicata({});
  codigoDuplicata = null;
  duplicata = {};
  loading = false;
  step = null;

  constructor(context, buscaDuplicataUseCase, validarDuplicataUseCase) {
    this.context = context;
    this.buscaDuplicataUseCase = buscaDuplicataUseCase;
    this.validarDuplicataUseCase = validarDuplicataUseCase;
  }

  async buscarDuplicata() {
    try {
      this.loading = true;
      this.codigoDuplicata = null;

      if (this.duplicataForm.codigo) {
        const duplicata = await this.buscaDuplicataUseCase(this.duplicataForm);
        this.duplicata = duplicata;
        this.codigoDuplicata = this.duplicataForm.codigo;
        this.step = 1;
      }
    } catch (error) {
      snackbar.show({ message: error.toString(), timeout: 4000 });
    } finally {
      this.loading = false;
    }
  }

  async validarDuplicata() {
    try {
      const confirm = await alert.confirm({
        message: 'Deseja validar a carta frete?',
        color: 'primary'
      });

      if (!confirm) {
        return;
      }

      this.loading = true;
      await this.validarDuplicataUseCase(this.codigoDuplicata);
      this.step = 2;
    } catch (error) {
      snackbar.show({ message: error.toString(), timeout: 4000 });
    } finally {
      this.loading = false;
    }
  }

  resetarDuplicata() {
    this.duplicata = {};
    this.loading = false;
    this.duplicataForm = new Duplicata({});
    this.codigoDuplicata = '';
    this.step = null;
  }
}

export default DashboardController;
