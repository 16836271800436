import Vue from 'vue';
import Login from '@/module/login/view/login.vue';
import Home from '@/module/home/view/Home.vue';

import { isUserLogged } from '@/core/data/domain/jwtAuth';

class DefaultController {
  component = null;
  navigateEnum = {
    login: Login,
    home: Home
  };

  mounted() {
    Vue.prototype.defaultNavigator = this;

    if (!isUserLogged()) {
      this.component = Login;
      return;
    }

    this.component = Home;
  }

  navigate(screen) {
    this.component = this.navigateEnum[screen];
  }
}

export default DefaultController;
