import Handler from "@/core/data/domain/usecase/handler";
import { URL } from "../../../../constants"

const primeiroAcessoRepository = (axios) => async (data) => {
  const dados = JSON.parse(data)
  try {
    const response = await axios.post(`${URL}/cfe/primeiro-acesso`, null, {
      headers: {
        authorization: `Bearer ${dados.token}`
    }
    });
    return response.data;
  } catch (error) {
    throw await await new Handler().trataErro(error);
  }
};

export default primeiroAcessoRepository;
