class User {
  constructor(
    usuario = "",
    senha = "",
    pacific = "",
    versao = 1,
    cliente = "",
    aplicativo = "cfe-web"
  ) {
    this.usuario = usuario
    this.senha = senha
    this.pacific = pacific
    this.versao = versao
    this.cliente = cliente
    this.aplicativo = aplicativo
  }
}

export default User;
