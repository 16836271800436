<template>
  <div>
    <v-card
      v-if="controller.step === 1"
      min-width="300"
      class="mx-auto rounded-md"
      max-width="800"
    >
      <v-card-title class="pa-0">
        <v-toolbar
          dense
          class="toolbar elevation-0 white--text"
          color="primary"
        >
          <v-icon left color="white"> mdi-currency-usd </v-icon>
          Duplicata {{ controller.duplicata.num_fatura }}
          <v-spacer></v-spacer>
          <span>{{ controller.duplicata.saldo | currency }}</span>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-card-subtitle class="px-0">{{
          controller.duplicata.nome_for
        }}</v-card-subtitle>
        <p><b>Número:</b> {{ controller.duplicata.num_fatura }}</p>
        <p><b>Série:</b> {{ controller.duplicata.serie_fatura }}</p>
        <p><b>Parcela:</b> {{ controller.duplicata.parcela }}</p>
        <p>
          <b>Vencimento:</b>
          {{ dayjs(controller.duplicata.dt_vencto).format('DD/MM/YYYY') }}
        </p>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn
          color="success"
          @click="controller.validarDuplicata()"
          :disabled="controller.loading"
        >
          <v-icon left> mdi-check </v-icon>
          Validar Carta
        </v-btn>
      </v-card-actions>
    </v-card>
    <div v-else class="d-flex align-center flex-column">
      <v-card-text class="d-flex justify-center align-center flex-column">
        <v-icon color="green" size="80">
          mdi-checkbox-marked-circle-outline
        </v-icon>
        <span class="mt-4 text-h4 primary--text"
          >Carta validada <br />com sucesso!</span
        >
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" outlined @click="controller.resetarDuplicata()">
          Nova consulta
        </v-btn>
      </v-card-actions>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    controller: {
      type: Object,
      required: true
    }
  },
  filters: {
    currency(value) {
      return `R$ ${value.toLocaleString('pt-br', {
        minimumFractionDigits: 2
      })}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.toolbar {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
</style>
