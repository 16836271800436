import sessionRepository from "@/module/login/data/repository/sessionRepository";
import refreshUseCase from "@/module/login/domain/usecase/refreshUseCase";
import { axiosInstance } from "@/plugins/axios";

class Handler {
  async trataErro(error) {
    if (error) {
      if (error.isAxiosError) {
        if (error.code == 'ERR_NETWORK') {
          return 'Falha na comunicação com o servidor';
        }

        switch (error.response.status) {
          case 500:
            return error.response.data.message ?? error.response.data?.logErroApp[0].erro
          default:
            return error.response.data.message;
        }
      }

      return error.data?.logErroApp[0].erro;
    }

    return error;
  }
}

export default Handler;
