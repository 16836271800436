import Handler from '@/core/data/domain/usecase/handler';

const buscaDuplicataRepository = (axios) => async (payload) => {
  try {
    const token = JSON.parse(localStorage.getItem('auth'), '{}');

    const response = await axios.post('/cfe/le-duplicata', payload, {
      headers: {
        Authorization: `Bearer ${token.token}`
      }
    });

    // const response = await axios.put('app/app', payload)
    // if (response?.data?.logErroApp) {
    //   throw response
    // }

    return response?.data?.cap_duplicata?.[0] || {};
  } catch (error) {
    throw await new Handler().trataErro(error);
  }
};

export default buscaDuplicataRepository;
